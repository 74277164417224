var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.queryParams }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "代理商ID：" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.queryParams.user_id,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParams, "user_id", $$v)
                      },
                      expression: "queryParams.user_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.queryParams.status,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParams, "status", $$v)
                        },
                        expression: "queryParams.status"
                      }
                    },
                    _vm._l(_vm.statusOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.requestData }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.data, border: "", stripe: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "user_id", label: "代理商ID" }
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "代理商名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "amount", label: "提现金额/元" }
          }),
          _c("el-table-column", {
            attrs: { prop: "base_amount", label: "服务费/元" }
          }),
          _c("el-table-column", {
            attrs: { prop: "payment_method", label: "提现方式" }
          }),
          _c("el-table-column", {
            attrs: { prop: "account", label: "账号信息", width: "220" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" 账号/卡号：" + _vm._s(scope.row.account)),
                    _c("br"),
                    _vm._v(" 账户名：" + _vm._s(scope.row.account_name)),
                    _c("br"),
                    _vm._v(
                      " 开户行/身份证：" + _vm._s(scope.row.extra.belong) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === 1
                      ? [_vm._v("通过")]
                      : scope.row.status === 2
                      ? [_vm._v("拒绝")]
                      : [_vm._v("待处理")]
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "created_at", label: "申请时间" }
          }),
          _c("el-table-column", {
            attrs: { prop: "deal_at", label: "处理时间" }
          }),
          _c("el-table-column", {
            attrs: { prop: "remark", label: "拒绝原因", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.remark && scope.row.status == 2
                      ? _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top-start",
                              width: "180",
                              trigger: "hover",
                              content: scope.row.remark
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#606266" },
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("handleRemark")(scope.row.remark)
                                  ) + " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return scope.row.status === 0
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "medium" },
                              on: {
                                click: function($event) {
                                  return _vm.handleAudit(scope.row, 1)
                                }
                              }
                            },
                            [_vm._v("通过")]
                          ),
                          _c("el-divider", {
                            attrs: { direction: "vertical" }
                          }),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "medium" },
                              on: {
                                click: function($event) {
                                  return _vm.handleAudit(scope.row, 2)
                                }
                              }
                            },
                            [_vm._v("拒绝")]
                          )
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
            _vm._v("请填写拒绝理由")
          ]),
          _c("el-input", {
            attrs: {
              type: "textarea",
              rows: 10,
              maxlength: "150",
              placeholder: "请输入内容"
            },
            model: {
              value: _vm.reReson.remark,
              callback: function($$v) {
                _vm.$set(_vm.reReson, "remark", $$v)
              },
              expression: "reReson.remark"
            }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: !_vm.reReson.remark, type: "primary" },
                  on: { click: _vm.refuseRefund }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.queryParams.page,
          "page-sizes": [20, 50, 100, 200],
          "page-size": _vm.queryParams.page_size,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total
        },
        on: {
          "size-change": _vm.requestData,
          "current-change": _vm.requestData,
          "update:currentPage": function($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:current-page": function($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }